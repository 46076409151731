<template>
  <div>
      <panel
          :categoryOptions="categoryOptions"
          :shopOptions="shopOptions"
          @add="addHandle"
          @edit="editHandle"
          @copy="copyHandle"
          @alternative="alternativeHandle"
          @promotion="promotionHandle"
          ref="panelRefs"
        ></panel>
    

    <EditDialog
      v-model="dialogVisible"
      @refresh="refreshHandle"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>

    <AlternativeDialog
      v-model="alternativeVisible"
      :alterData="alterData"
    ></AlternativeDialog>

    <Promotion
      v-model="promotionVisible"
      :promotionData="promotionData"
    ></Promotion>
  </div>
</template>

<script>
import { getCategoryList } from "@/apis/unidrink/category";
import { getShopOptions } from "@/apis/unidrink/shop";
import panel from "./panel";
import EditDialog from "./editDialog";
import AlternativeDialog from "./alternativeDialog.vue";
import Promotion from "./promotion.vue";
import { getShopList } from "@/apis/auth";
import { msgErr, msgSuc } from "@/helpers/message";
export default {
  components: { panel, EditDialog, AlternativeDialog, Promotion },
  data() {
    return {
      dialogVisible: false,
      editData: {},
      alternativeVisible: false,
      alterData: {},
      promotionVisible: false,
      promotionData: {},
      editMode: "add",
      panelRefs: {},
      categoryOptions: [],
      shopOptions: [],
      shopId:"",
    };
  },
  created() {
    this.activeTab = this.categoryOptions.length
      ? this.categoryOptions[0].value
      : "";
  },
  async activated() {
    await this.getCategoryOptions();
    getShopOptions(this.shopOptions);
    this.refreshHandle();
  },
  methods: {
    setItemRef(el, key) {
      this.panelRefs[key] = el;
    },
    addHandle() {
      this.editMode = "add";
      this.dialogVisible = true;
    },

    editHandle(row) {
      console.log("editHandle", row);
      this.editMode = "edit";
      this.editData = row;
      this.dialogVisible = true;
    },

    copyHandle(row) {
      console.log("copyHandle", row);
      this.editMode = "copy";
      this.editData = row;
      this.dialogVisible = true;
    },

    alternativeHandle(row) {
      console.log("alternativeHandle", row);
      this.alterData = row;
      this.alternativeVisible = true;
    },

    promotionHandle(row) {
      this.promotionData = row;
      this.promotionVisible = true;
    },

    refreshHandle() {
      console.log(this.activeTab);
      this.$refs.panelRefs.getList();
    },

    async getCategoryOptions() {
      this.categoryOptions = [];
      await getCategoryList("all").then(({ rows, total }) => {
        this.categoryOptions.push(
          ...rows.map((row) => {
            return {
              label: row.name.replace(/[&nbsp;|└]/g, ""),
              value: row.id,
            };
          })
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
